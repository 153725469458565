const steps = [
	{
		element: '#hamburger-container',
		popover: {
			title: 'Hamburger',
			description: '控制侧边菜单类的展示/隐藏',
			position: 'bottom'
		}
	},
	{
		element: '#breadcrumb-container',
		popover: {
			title: 'Breadcrumb',
			description: '当前访问路径',
			position: 'bottom'
		}
	},
	{
		element: '#screenfullSvg',
		popover: {
			title: 'Screenfull',
			description: '可以控制全屏展示',
			position: 'left'
		}
	},
	{
		element: '#tags-view-container',
		popover: {
			title: 'Tags view',
			description: '历史访问记录',
			position: 'bottom'
		},
		padding: 0
	}
];

export default steps
